<template>
  <div>
    {{selectedThirdParty}}
    <b-tabs  lazy>
      <b-tab title="Overall Risk Posture">
        <risk-posture-listing @selectedRisk="thirdPartyClicked"/>
      </b-tab>
      <b-tab title="All Risks" ref="RiskPostureRef">
        <risk-listing :filterThirdParty="true" :thirdPartyFilter="selectedThirdParty" @clearThirdParty="clearSelectedThirdParty"/>
      </b-tab>
    </b-tabs>
  </div>
  
</template>
<script>
import RiskListing from "./components/RiskListing.vue";
import RiskPostureListing from "./components/RiskPostureListing.vue";
import { 
  BTabs,
  BTab,
 } from "bootstrap-vue";
export default{
  components:{
    RiskListing,
    BTab,
    BTabs,
    RiskPostureListing
  },
  data(){
    return {
      selectedThirdParty:null
    }
  },
  methods:{
    thirdPartyClicked(vendor){
      this.selectedThirdParty=vendor
      this.$refs.RiskPostureRef.activate()
    },
    clearSelectedThirdParty(){
      console.log('clearSelectedThirdParty')
      this.selectedThirdParty=null
    }
  }

}
</script>

<style scoped>
.custom-dropdown .dropdown-menu {
  z-index: 999; /* Set a higher z-index */
}
</style>